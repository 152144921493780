:root {
  --background-fk: #2a3237;
  --background-tv4play: rgb(25, 24, 23);
  --background-tv4se: rgb(20, 20, 20);
  --bg-image-tv4play: url('../images/tv4play_bg.svg');
  --bg-image-mtv: url('../images/mtv_bg.svg');
  --accent-koket: #4e9a5d;
  --accent-dark-koket: hsl(132, 33%, 35%);
  --accent-fk: #4ec386;
  --accent-tv4se: hsl(360, 100%, 60%);
  --accent-dark-tv4se: hsl(360, 100%, 30%);
  --logo-tv4se: url('../images/tv4play_logo.png');
  --logo-tv4play: url('../images/tv4play_logo.png');
  --logo-koket: url('../images/koket-logo-primary.svg');
  --logo-fk: url('../images/fotbollskanalen_transparent_white_logo.png');
  --logo-mtv: url('../images/mtvkatsomo_logo.png');
  --loader-fk: url('../images/fotbollskanalen_loader.png');
  --loader-tv4play: url('../images/tv4play_loader.png');
}

body.tv4se {
  --playback-logo-image: var(--logo-tv4se);
}

body.tv4play {
  --playback-logo-image: var(--logo-tv4play);
}

body.koket {
  --playback-logo-image: var(--logo-koket);
}

body.fotbollskanalen {
  --playback-logo-image: var(--logo-fk);
}

.tv4se cast-media-player {
  --logo-image: var(--logo-tv4se);
  --background-color: var(--background-tv4se);
  --progress-color: var(--accent-tv4se);
  --buffering-image: var(--loader-tv4play);
  --spinner-image: var(--loader-tv4play);
  --logo-size: 10%;
}

.tv4play cast-media-player {
  --logo-image: var(--logo-tv4play);
  --background-color: var(--background-tv4play);
  --progress-color: var(--accent-tv4se);
  --buffering-image: var(--loader-tv4play);
  --spinner-image: var(--loader-tv4play);
  --background-image: var(--bg-image-tv4play);
  --logo-size: 20%;
}

.fotbollskanalen cast-media-player {
  --logo-image: var(--logo-fk);
  --background-color: var(--background-fk);
  --progress-color: var(--accent-fk);
  --buffering-image: var(--loader-fk);
  --spinner-image: var(--loader-fk);
}

.koket cast-media-player {
  --background-color: #000;
  --logo-image: var(--logo-koket);
  --logo-size: 12%;
  --progress-color: var(--accent-koket);
}

.mtv cast-media-player {
  --logo-image: var(--logo-mtv);
  --background-color: var(--background-tv4play);
  --progress-color: var(--accent-tv4se);
  --buffering-image: var(--loader-tv4play);
  --spinner-image: var(--loader-tv4play);
  --background-image: var(--bg-image-mtv);
  --logo-size: 20%;
}
