@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.75;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.25;
  }

  100% {
    opacity: 0;
  }
}

body {
  background-color: #000000;
  overflow: hidden;
}

.error-container {
  display: none;
}

.error-container:not(:empty) {
  background-color: black;
  border-radius: 10px;
  color: white;
  display: block;
  font-size: 2em;
  left: 50%;
  padding: 10px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.error-container > .code {
  font-size: 0.5em;
}

.overlay-container > .debug-container {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  color: white;
  display: none;
  left: 5%;
  max-width: 88%;
  padding: 1%;
  position: absolute;
  top: 5%;
  word-break: break-all;
  z-index: 99999999;
}

.overlay-container > .debug-container.visible {
  display: block;
}

.overlay-container > .notifications-container::before {
  background: no-repeat center center;
  background-size: 40px 40px;
  border-radius: 5px 0 0 5px;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  min-width: 60px;
  position: absolute;
  top: 0;
  width: 10%;
}

.overlay-container > .notifications-container {
  background-color: rgba(6, 17, 31, 0.6);
  border-radius: 5px;
  color: white;
  line-height: 120%;
  max-width: 41%;
  opacity: 0;
  padding: 1% 1% 1% 1%;
  position: absolute;
  right: 5%;
  text-align: left;
  top: 5%;
  z-index: 99999999;
}

.overlay-container > .notifications-container.fadeOut {
  animation-direction: alternate;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-name: fadeOut;
  animation-timing-function: ease-out;
}

.overlay-container > .notifications-container.fadeIn {
  animation-direction: alternate;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-name: fadeIn;
  animation-timing-function: ease-in;
  opacity: 1;
}

.overlay-container > .sample-container {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  color: white;
  display: none;
  left: 5%;
  max-width: 88%;
  padding: 1%;
  position: absolute;
  top: 5%;
  word-break: break-all;
}

.overlay-container > .sample-container.visible {
  display: block;
}

cast-media-player {
  animation-direction: alternate;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-name: fadeIn;
  animation-timing-function: ease-in;
  opacity: 1;
}
